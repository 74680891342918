<template>
  <div class="home">
      <TopBar />
      <PrimeiraSecao 
      calltoaction = "Quero vender + na internet" />
      <SegundaSecao />
      <TerceiraSecao />
      <QuartaSecao />
      <QuintaSecao />
      <SextaSecao />
      <SetimaSecao />
      <OitavaSecao />
      <NonaSecao />
      <DecimaSecao />
      <SecaoMarcas />
      <SecaoDoze />
      <SecaoParcerias />
      <Rodape />
      <Popup 
      :show="showDialog"
      titulo="Prencha o formulário para falar com nossos consultores"
      :cancel="cancel"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import PrimeiraSecao from '@/components/PrimeiraSecao.vue'
import TopBar from '@/components/TopBar.vue'
import SegundaSecao from '@/components/SegundaSecao.vue'
import TerceiraSecao from '@/components/TerceiraSecao.vue'
import QuartaSecao from '@/components/QuartaSecao.vue'
import QuintaSecao from '@/components/QuintaSecao.vue'
import SetimaSecao from '@/components/SetimaSecao.vue'
import OitavaSecao from '@/components/OitavaSecao.vue'
import NonaSecao from '@/components/NonaSecao.vue'
import DecimaSecao from '@/components/DecimaSecao.vue'
import SecaoMarcas from '@/components/SecaoMarcas.vue'
import SecaoDoze from '@/components/SecaoDoze.vue'
import SecaoParcerias from '@/components/SecaoParcerias.vue'
import Rodape from '@/components/Rodape.vue'
import Popup from '@/components/Popup.vue'
import SextaSecao from '@/components/SextaSecao.vue'

export default {
  name: 'Home',
  data: () => ({
      showDialog: false
  }),
  props: [
    'mostrar'
  ],
  methods: {
    cancel () {
        this.showDialog = false;
      },
    mostrar () {
      this.showDialog = true
    }
  },
  components: {
    PrimeiraSecao,
    SegundaSecao,
    TopBar,
    TerceiraSecao,
    QuartaSecao,
    QuintaSecao,
    SetimaSecao,
    OitavaSecao,
    NonaSecao,
    DecimaSecao,
    SecaoMarcas,
    SecaoDoze,
    SecaoParcerias,
    Rodape,
    Popup,
    SextaSecao
}
}
</script>

<style scoped>


/* media css */

@media (max-width: 1440px) {  }

@media (max-width: 1399px) {  }

@media (max-width: 1199.98px) {  }

@media (max-width: 1024px) {  }

@media (max-width: 991.98px) {  }

@media (max-width: 767.98px) {  }

@media (max-width: 575.98px) {  }

@media (max-width: 320px) {  }


</style>