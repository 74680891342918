<template>
    <form id="formulario" action="https://www.rdstation.com.br/api/1.2/conversions" method="POST">
        <input type="hidden" name="token_rdstation" :value="token_rdstation" />
        <input type="hidden" name="identificador" value="Lead LP Mercado Binário" />
        <input type="hidden" name="redirect_to" value="https://api.whatsapp.com/send?phone=5541987020165&text=Gostaria%20de%20agendar%20uma%20consultoria%20gratuita" />
        <div class="mb-3 form-row">
        <input type="text" class="form-control input-text" id="nome" :placeholder="nome" :required="true">
        </div>
        <div class="mb-3 form-row">
        <input type="text" class="form-control input-text" id="telefone" :placeholder="telefone" v-maska="'(##) #####-####'" :required="true">
         </div>
         <div class="mb-3 form-row">
        <input type="email" name="email" class="form-control input-text" id="email" autocomplete="on" :placeholder="email" :required="true">
         </div>
         <div class="mb-3 form-row">
        <input type="site" class="form-control input-text" id="site" :placeholder="site" :required="true">
        <button type="submit" id="submit" class="btn btn-primary btn-block mb-4">{{ textSubmit }}</button>
         </div>
         <span class="termos">Suas informações estão seguras conosco. <span style="color: #4BC0AD; font-weight: 700">
         <a href="https://mercadobinario.com.br/politica-de-privacidade/" target="_blank">
         Política de Privacidade.</a></span></span>
    </form>     
</template>

<script>
export default {
    name: 'Form',
    data: () => ({
        token_rdstation: '49c0953782b4c2149897fddb955eb0bb',
        requerid: false,
        nome: null,
        telefone: null,
        email: null,
        site: null
    }),
    props: {
        textSubmit: String
    },
    methods: {
        async getDados() {
            try {

            const req = await fetch ("https://api.mercadobinario.com.br/public/api/formulario");
            const data = await req.json();
            this.nome = data[0].nome
            this.telefone = data[0].telefone
            this.email = data[0].email
            this.site = data[0].site
                
            } catch (error) {
                
            this.nome = 'Nome'
            this.telefone = 'Telefone'
            this.email = 'E-mail'
            this.site = 'Site'

            }
        }
    },
    mounted() {
        this.getDados();
    }
    
}
</script>

<style scoped>

span.termos {
    font-size: 10px;
    line-height: 26px;
    font-weight: 400;
    color: #474747;
    text-align: center;
    position: relative;
    display: block;
}

button#submit {
    display: block;
    margin: 30px 0px;
    width: 100%;
    background-color: #00DE06;
    border: 0px;
    padding: 10px 15px 10px 15px;
    border-radius: 31px;
    font-size: 16px;
    line-height: 28px;
    font-weight: 700;
    color: #2C2C2C;
}

.form-control {
    display: block;
    width: 100%;
    padding: 10px 15px 10px 15px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 31px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

@media (max-width: 575.98px) { 

    span.termos {
    font-size: 12px;
    line-height: 21px;
    font-weight: 400;
    color: #474747;
    text-align: center;
    position: relative;
    display: block;
}
 }

@media (max-width: 360px) { 

 span.termos {
    font-size: 12px;
    line-height: 21px;
    font-weight: 400;
    color: #474747;
    text-align: center;
    position: relative;
    display: block;
}

 }

@media (max-width: 320px) { 


 span.termos {
    font-size: 12px;
    line-height: 21px;
    font-weight: 400;
    color: #474747;
    text-align: center;
    position: relative;
    display: block;
}

 }

</style>>