<template>
  <swiper :pagination="true" :modules="modules" :slidesPerView="3" :autoplay="{
      delay: 2500,
      disableOnInteraction: false,
    }"
    class="mySwiper">
    <swiper-slide><img src="@/assets/img/smarcas/graciosa.jpg"></swiper-slide>
    <swiper-slide><img src="@/assets/img/smarcas/beegreen.jpg"></swiper-slide>
    <swiper-slide><img src="@/assets/img/smarcas/rottas.jpg"></swiper-slide>
    <swiper-slide><img src="@/assets/img/smarcas/sykes.jpg"></swiper-slide>
    <swiper-slide><img src="@/assets/img/smarcas/votorantin.jpg"></swiper-slide>
  </swiper>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";

import "swiper/css/effect-fade"

// import required modules
import { Pagination, Autoplay } from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Pagination, Autoplay],
    };
  },
};
</script>

<style scoped>



.swiper-slide img {
    display: block;
    width: 170px;
    height: 100px;
    -o-object-fit: cover;
    object-fit: cover;
}

.swiper {
  width: 100%;
  height: 260px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 0px!important;
    left: 0;
    width: 100%;
}

</style>
