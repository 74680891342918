<template>
  <div class="SecaoMarcas desktop">
    <div class="container">
      <div class="row d-flex align-items-center">
        <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 align-itens-center text-center">
                <span class="TituloMarcas">
                    Marcas satisfeitas<br> com o método MB
                </span>
        </div>
        <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 justify-content-end">
                <SliderMarcas />
        </div>
      </div>
    </div>
  
  </div>
  <div class="SecaoMarcas mobile">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 align-itens-center text-center">
                <span class="TituloMarcas">
                    Marcas satisfeitas<br> com o método MB
                </span>
        </div>
        <div class="col-6 col-md-1 col-sm-4 col-lg-1 col-xl-1" v-for="marca in marcas" :key="marca.id">
            <center><img class="imagemmarcas" :src="marca.imagem"></center>
        </div>
      </div>
    </div>
  
  </div>
</template>

<script>
// @ is an alias to /src
import SliderMarcas from '@/components/SliderMarcas.vue'

export default {
  name: 'SecaoMarcas',
  data: () => ( {
        marcas: [
          {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/graciosa.jpg'},
          {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/votorantin.jpg'},
          {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/sykes.jpg'},
          {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/rottas.jpg'},
          {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/ceara.svg'},
          {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/beegreen.jpg'},
          {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/econew.svg'},
          {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/incorpore.svg'},
        ]
  }),
  components: {
    SliderMarcas
  }
}
</script>

<style scoped>


/* media css */

.textobranco {
  color: #fff;
}

@media (max-width: 2000px) { 

    .desktop {
    display: block;
}

.mobile {
  display: none;
}

.SecaoMarcas {
    height: 580px;
    background-color: #fff;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    padding: 100px 0px;
}

span.TituloMarcas {
    font-weight: 900;
    font-size: 28px;
    line-height: 39px;
    text-transform: uppercase;
    background: #1D4E88;
    background: linear-gradient(to right, #1D4E88 39%, #491C77 54%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

 }

@media (max-width: 1440px) { 
  

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 1399px) { 

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 1199.98px) { 

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 1024px) { 

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 991.98px) { 

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 768px) { 

  img.imagemmarcas {
    width: 88%;
    height: auto;
    margin-top: 27px;
}

 .desktop {
    display: none;
}

.mobile {
  display: block;
}

 }

@media (max-width: 575.98px) { 

  .SecaoMarcas {
    height: auto;
    background-color: #fff;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    padding: 100px 0px;
}

 .desktop {
    display: none;
}

.mobile {
  display: block;
}
img.imagemmarcas {
    width: 84%;
    height: 92px;
}

 }

@media (max-width: 360px) { 

    .desktop {
    display: none;
}

.mobile {
  display: block;
}
img.imagemmarcas {
    width: 84%;
    height: 92px;
}

 }
@media (max-width: 320px) { 

.desktop {
    display: none;
}

.mobile {
  display: block;
}

img.imagemmarcas {
    width: 84%;
    height: 92px;
}

 }


</style>