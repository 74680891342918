<template>
  <div class="SecaoParcerias desktop">
    <div class="container">
      <div class="row d-flex align-items-center">
        <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 align-itens-center text-center">
            <span class="tituloParcerias">
                Empresas parceiras no<br>sucesso de nossos clientes: 
            </span>
        </div>
        <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 justify-content-center">
          <SliderParcerias />
        </div>
      </div>
    </div>
  </div>

  <div class="SecaoParcerias mobile">
    <div class="container">
      <div class="row d-flex align-items-center">
        <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 align-itens-center text-center">
            <span class="tituloParcerias">
                Empresas parceiras no<br>sucesso de nossos clientes: 
            </span>
        </div>
        <div class="col-12 col-md-12 col-sm-6 col-lg-12 col-xl-12 d-flex justify-content-center" v-for="parceiro in parceiros" :key="parceiro.id">
              <img class="parceiros" :src="parceiro.imagem">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SliderParcerias from '@/components/SliderParcerias.vue'

export default {
  name: 'SecaoParcerias',
  data: () => ({
        parceiros: [
            {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/google.svg'},
            {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/olist.svg'},
            {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/rdstation.svg'},
            {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/tray.svg'}
        ],
    }),
  components: {
    SliderParcerias
  }
}
</script>

<style scoped>


/* media css */

@media (max-width: 2000px) { 

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

    /* 2 */

    .SecaoParcerias {
    height: auto;
    padding: 100px 0px;
    background-image: url('@/assets/img/secaoparcerias/bgultimasecao.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
span.tituloParcerias {
    text-transform: uppercase;
    font-size: 28px;
    font-weight: 700;
    line-height: 39px;
    color: #fff;
}

 }

@media (max-width: 1440px) { 

  .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

 }

@media (max-width: 1399px) { 

  .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

 }

@media (max-width: 1199.98px) { 

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

 }

@media (max-width: 1024px) { 

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

 }

@media (max-width: 991.98px) { 

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

 }

@media (max-width: 768px) { 

  span.tituloParcerias {
    text-transform: uppercase;
    font-size: 28px;
    font-weight: 700;
    line-height: 39px;
    color: #fff;
    margin: 29px 0px;
    display: block;
}

  img.parceiros {
    margin: 21px;
    width: 52%;
}

 .desktop {
    display: none;
}

.mobile {
  display: block;
}

 }

@media (max-width: 575.98px) { 

 .desktop {
    display: none;
}

.mobile {
  display: block;
}

img.parceiros {
    width: 30%;
    display: block;
    padding: 50px 0px;
    position: relative;
}

span.tituloParcerias {
    text-transform: uppercase;
    font-size: 23px;
    font-weight: 700;
    line-height: 39px;
    color: #fff;
    margin: 29px 0px;
    display: block;
}

 }

@media (max-width: 360px) { 

    .desktop {
    display: none;
}

.mobile {
  display: block;
}

img.parceiros {
    width: 43%;
    display: block;
    padding: 50px 0px;
    position: relative;
}
 }
@media (max-width: 320px) { 

.desktop {
    display: none;
}

.mobile {
  display: block;
}

img.parceiros {
    width: 43%;
    display: block;
    padding: 50px 0px;
    position: relative;
}
 }

</style>