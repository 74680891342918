<template>
    <transition name="slide-fade">
      <div class="modal-overlay" v-show="show" :mostrar="mostrar"> 
            <div class="modal-body">
              <div class="modal">
                <h1 id="titulo" v-text="titulo"></h1> 
                <div class="form">
                  <Formulario 
                  
                  textSubmit="Agendar consultoria gratuita" 
                  
                  />
                </div> 
                <a class="button" id="fecha"  @click="cancel">
                  <svg id="imgfecha" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="40" height="40" viewBox="0 0 30 30" style=" fill:#undefined;"> <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"></path></svg>
                </a>
              </div>
            </div>  
      </div>
      </transition> 

</template>

<script>
import Formulario from '@/components/Form.vue'

export default {
  name: 'Popup',
  data: () => ({
    showModal: false
  }),
   components: {
    Formulario
},
  props: [
    'show', 'titulo', 'abrir', 'cancel'
  ],
  methods: {
      mostrar() {
      this.showDialog = true
    },

  }
}

</script>

<style scoped>

#titulo {
  text-align: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 98;
  background-color: rgba(0, 0, 0, 0.3);
}

@media (min-width: 1441px) {

  .modal {
    position: relative;
    z-index: 126;
    width: 100%;
    height: auto;
    max-width: 500px;
    background-color: #fff;
    border-radius: 16px;
    padding: 25px;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin:auto;
    left: 0%;
    margin-top: 10%;
}

 }
.modal h1 {
    padding: 20px 0px;
    color: #222;
    font-size: 25px;
    font-weight: 900;
    margin-bottom: 15px;
}
.modal p {
  color: #666;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 15px;
}

svg#imgfecha {
    position: absolute;
    right: 0px;
    top: 0px;
    display: block;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    color: #fff;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    transition: .5s;
    width: 11%;
}

svg#imgfecha:hover {
    position: absolute;
    right: 0px;
    top: 0px;
    display: block;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    color: #fff;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    transition: .5s;
    width: 11%;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(0.5, 0.5, 0.5, 0.5);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(5px);
  opacity: 0;
  transition: 0.1s;
}

@media (min-width: 1600px) {
.modal {
    position: relative;
    top: 50px;
    z-index: 126;
    width: 100%;
    height: auto;
    max-width: 500px;
    background-color: #fff;
    border-radius: 16px;
    padding: 25px;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    left: 0%;
    margin-top: 10%;
}
}

@media (max-width: 1440px) {

  .modal {
    position: relative;
    top: 50px;
    left: 0%;
    z-index: 126;
    width: 100%;
    height: auto;
    max-width: 500px;
    background-color: #fff;
    border-radius: 16px;
    padding: 25px;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin:auto;
}
}
@media (max-width: 1399.98px) {

.modal {
    position: relative;
    top: 50px;
    left: 0%;
    z-index: 126;
    width: 100%;
    height: auto;
    max-width: 500px;
    background-color: #fff;
    border-radius: 16px;
    padding: 25px;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin:auto;
}

 }
@media (max-width: 1199.98px) {

  .modal {
    position: relative;
    top: 50px;
    left: 0%;
    z-index: 126;
    width: 100%;
    height: auto;
    max-width: 500px;
    background-color: #fff;
    border-radius: 16px;
    padding: 25px;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

 }

@media (max-width: 1024px) {
.modal {
    position: relative;
    top: 50px;
    left: 0%;
    z-index: 126;
    width: 100%;
    height: auto;
    max-width: 500px;
    background-color: #fff;
    border-radius: 16px;
    padding: 25px;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
}

@media (max-width: 991.98px) {

  .modal {
    position: relative;
    top: 50px;
    left: 0%;
    z-index: 126;
    width: 100%;
    height: auto;
    max-width: 500px;
    background-color: #fff;
    border-radius: 16px;
    padding: 25px;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

 }
@media (max-width: 768px) { 

    .modal {
    position: relative;
    top: 50px;
    left: 0%;
    z-index: 126;
    width: 100%;
    height: auto;
    max-width: 500px;
    background-color: #fff;
    border-radius: 16px;
    padding: 25px;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

}

@media (max-width: 575.98px) {

  .modal {
    position: relative;
    top: 50px;
    left: 0%;
    z-index: 126;
    width: 95%;
    height: auto;
    max-width: 455px;
    background-color: #fff;
    border-radius: 16px;
    padding: 25px;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

 }

@media (max-width: 540px) {
 .modal {
    position: relative;
    top: 50px;
    left: 0%;
    z-index: 126;
    width: 95%;
    height: auto;
    max-width: 455px;
    background-color: #fff;
    border-radius: 16px;
    padding: 25px;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
}

 @media (max-width: 414px){

   .modal h1 {
    padding: 20px 0px;
    color: #222;
    font-size: 22px;
    font-weight: 900;
    margin-bottom: 15px;
}

.modal {
    position: relative;
    top: 50px;
    left: 0%;
    z-index: 126;
    width: 95%;
    height: auto;
    max-width: 455px;
    background-color: #fff;
    border-radius: 16px;
    padding: 25px;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
 }

@media (max-width: 375px){
 .modal {
    position: relative;
    
    z-index: 126;
    width: 95%;
    height: auto;
    max-width: 455px;
    background-color: #fff;
    border-radius: 16px;
    padding: 25px;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
}

@media (max-width: 360px) {

.modal {
    position: relative;
    top: 50px;
    left: 0%;
    z-index: 126;
    width: 95%;
    height: auto;
    max-width: 455px;
    background-color: #fff;
    border-radius: 16px;
    padding: 25px;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.modal h1 {
    padding: 20px 0px;
    color: #222;
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 15px;
}
}

@media (max-width: 280px) {
.modal {
    position: relative;
    top: 50px;
    left: 0%;
    z-index: 126;
    width: 95%;
    height: auto;
    max-width: 455px;
    background-color: #fff;
    border-radius: 16px;
    padding: 25px;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.modal h1 {
    padding: 20px 0px;
    color: #222;
    font-size: 14px;
    font-weight: 900;
    margin-bottom: 15px;
}
button#enviar {
    margin-top: 20px;
    background-color: var(--terceira);
    font-size: 14px;
    color: #fff;
    border-radius: 65px;
}
}
  
</style>