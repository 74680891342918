<template>
  <div class="terceiraSecao">
        <div class="container">
      <div class="row">
        <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12">
          <span class="titulo" id="tituloterceira">
            Se não está na internet,<br> é porque não existe
          </span>
        </div>
        <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 text-center text-white text-break p-5">
            <span class="subtitulo">
              As mudanças de comportamento e preferências do consumidor​ definem os caminhos do mercado. <strong>O 
              resultado é visível: os números de compras pela internet só aumentam.</strong> O faturamento do e-commerce, no 
              Brasil, cresce a cada ano e apresenta um cenário favorável para empresas preparadas para vender online.
            </span>
        </div>
        <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 desktop" id="acima">
              <Charts />
        </div>
         <div class="col-12 col-md-6 col-sm-12 col-lg-6 col-xl-6">
          <div class="hour">
            <span class="estaNaHora text-white">
              Está na hora de você investir com mais segurança no marketing digital.
            </span>
            <p class="cresca">
              Cresça com as vendas online no Brasil.
            </p>
          </div>  
        </div>
         <div class="col-12 col-md-6 col-sm-6 col-lg-6 col-xl-6 d-flex align-items-center justify-content-lg-end">
           <a href="https://api.whatsapp.com/send/?phone=5541987230882&text=Gostaria+de+informa%C3%A7%C3%B5es+sobre+como+usar+IA+no+marketing+e+vendas" target="_blank"><BotaoSite class="desktop" calltoaction="Também quero vender + na internet" /></a>
        </div>
        <div class="col-12 col-md-6 col-sm-12 col-lg-6 col-xl-6 d-flex align-items-center justify-content-center mobile">
           <center><a href="https://api.whatsapp.com/send/?phone=5541987230882&text=Gostaria+de+informa%C3%A7%C3%B5es+sobre+como+usar+IA+no+marketing+e+vendas" target="_blank"><BotaoSite class="mobile" calltoaction="Também quero vender + na internet" /></a></center>
        </div>
      </div>
    </div>
  </div>
  <Popup 
      :show="showDialog"
      titulo="Preencha o formulário para falar com nossos consultores"
      :cancel="cancel"
    />
</template>

<script>
import BotaoSite from './BotaoSite.vue';
import Charts from './Charts.vue';
// @ is an alias to /src
import Popup from '@/components/Popup.vue'

export default {
  name: 'TerceiraSecao',
  data: () => ({
    showDialog: false
  }),
  props: [
    'mostrar'
  ],
  methods: {
    cancel () {
        this.showDialog = false;
      },
    mostrar () {
      this.showDialog = true
    }
  },
  components: {
    BotaoSite,
    Charts,
    Popup
}
}
</script>

<style scoped>


/* media css */
@media (max-width: 2000px) { 

  .acima {
    display: block;
    position: relative;
    z-index: 9999;
  }

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

    .terceiraSecao {
    height: auto;
    background-image: url('@/assets/img/3/03.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    margin-top: -133px;
    z-index: 0;
    display: block;
    position: relative;
    padding: 126px 0px;
}

span#tituloterceira {
    display: block;
    text-align: center;
    color: var(--branco);
}

span.estaNaHora {
    font-size: 24px;
    line-height: 31px;
    font-weight: 900;
}

p.cresca {
    font-size: 20px;
    line-height: 25px;
    font-weight: 400;
    color: var(--branco);
    padding-top: 20px;
}

button.botaoPadrao {
    background-color: #00C02A;
    width: 357px;
    height: 38px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
    left: -68px;
    position: relative;
}
button.botaoPadrao:hover {
    background-color: #00C02A;
    width: 357px;
    height: 38px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
    left: -68px;
    position: relative;
}

.hour {
    position: relative;
    display: block;
    left: 50px;
}

 }

@media (max-width: 1440px) { 

  .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

 }

@media (max-width: 1399px) { 

  .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

    .hour {
    position: relative;
    display: block;
    left: 23px;
}

button.botaoPadrao {
    background-color: #00C02A;
    width: 355px;
    height: 38px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
    left: -61px;
    position: relative;
}
button.botaoPadrao:hover {
    background-color: #00C02A;
    width: 355px;
    height: 38px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
    left: -61px;
    position: relative;
}
 }

@media (max-width: 1199.98px) { 

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

 }

@media (max-width: 1024px) { 

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

 }

@media (max-width: 991.98px) { 

  .terceiraSecao {
    height: auto;
    background-image: url('@/assets/img/3/03.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    margin-top: 0px;
    z-index: 0;
    display: block;
    position: relative;
    padding: 126px 0px;
}

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

    button.botaoPadrao {
    background-color: #00C02A;
    width: auto;
    height: 38px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
    left: 0px;
    position: relative;
}

    button.botaoPadrao:hover {
    background-color: #00C02A;
    width: auto;
    height: 38px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
    left: 0px;
    position: relative;
}
.hour {
    position: relative;
    display: block;
    left: 0px;
}

 }

@media (max-width: 767.98px) { 

  button.botaoPadrao {
    background-color: #00C02A;
    width: auto;
    height: 38px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
    margin: 30px 0px 0px 0px;
}

button.botaoPadra:hover {
    background-color: #00C02A;
    width: auto;
    height: 38px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
    margin: 30px 0px 0px 0px;
}

span.estaNaHora {
    font-size: 24px;
    line-height: 31px;
    font-weight: 900;
    text-align: center;
    display: block;
}

p.cresca {
    font-size: 20px;
    line-height: 25px;
    font-weight: 400;
    color: var(--branco);
    padding-top: 20px;
    text-align: center;
}
 .desktop {
    display: none;
}

.mobile {
  display: block;
}

 }

@media (max-width: 575.98px) { 

 .desktop {
    display: none;
}

.mobile {
  display: block;
}

.terceiraSecao {
    height: auto;
    background-image: url('@/assets/img/3/03.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    margin-top: -76px;
    z-index: 0;
    display: block;
    position: relative;
    padding: 126px 0px 70px 0px;
}
.titulo {
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    font-family: var(--font-padrao);
    text-transform: uppercase;
}
.subtitulo {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    font-family: var(--font-padrao);
    display: block;
}
span.estaNaHora {
    font-size: 20px;
    line-height: 25px;
    font-weight: 900;
    text-align: center;
    display: block;
}
p.cresca {
    font-size: 20px;
    line-height: 25px;
    font-weight: 400;
    color: var(--branco);
    padding-top: 20px;
    text-align: center;
}

button.botaoPadrao {
    background-color: #00C02A;
    width: 222px;
    height: 65px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 19px;
    transition: .2s;
    z-index: 99999;
}

button.botaoPadrao:hover {
    background-color: #00C02A;
    width: 222px;
    height: 65px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 19px;
    transition: .2s;
    z-index: 9999;
}

 }

@media (max-width: 360px) { 

    .desktop {
    display: none;
}

.mobile {
  display: block;
}

.terceiraSecao {
    height: auto;
    background-image: url('@/assets/img/3/03.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    margin-top: -76px;
    z-index: 0;
    display: block;
    position: relative;
    padding: 126px 0px 70px 0px;
}
.titulo {
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    font-family: var(--font-padrao);
    text-transform: uppercase;
}
.subtitulo {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    font-family: var(--font-padrao);
    display: block;
}

span.estaNaHora {
    font-size: 20px;
    line-height: 25px;
    font-weight: 900;
    text-align: center;
    display: block;
}
p.cresca {
    font-size: 20px;
    line-height: 25px;
    font-weight: 400;
    color: var(--branco);
    padding-top: 20px;
    text-align: center;
}
button.botaoPadrao {
    background-color: #00C02A;
    width: 222px;
    height: 65px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 19px;
    transition: .2s;
    z-index: 99999;
}
button.botaoPadrao:hover {
    background-color: #00C02A;
    width: 222px;
    height: 65px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 19px;
    transition: .2s;
    z-index: 99999;
}

 }
@media (max-width: 320px) { 

.desktop {
    display: none;
}

.mobile {
  display: block;
}

.terceiraSecao {
    height: auto;
    background-image: url('@/assets/img/3/03.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    margin-top: -76px;
    z-index: -7;
    display: block;
    position: relative;
    padding: 126px 0px 70px 0px;
}

.titulo {
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    font-family: var(--font-padrao);
    text-transform: uppercase;
}
.subtitulo {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    font-family: var(--font-padrao);
    display: block;
}
span.estaNaHora {
    font-size: 20px;
    line-height: 25px;
    font-weight: 900;
    text-align: center;
    display: block;
}

p.cresca {
    font-size: 20px;
    line-height: 25px;
    font-weight: 400;
    color: var(--branco);
    padding-top: 20px;
    text-align: center;
}
button.botaoPadrao {
    background-color: #00C02A;
    width: 222px;
    height: 65px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 19px;
    transition: .2s;
    z-index: 99;
}
button.botaoPadrao:hover {
    background-color: #00C02A;
    width: 222px;
    height: 65px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 19px;
    transition: .2s;
    z-index: 99;
}

 }

</style>