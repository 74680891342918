<template>

        <div class="SecaoDoze desktop">
            <div class="container">
            <div class="row d-flex">
                <div class="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4 text-center">
                    
                </div>
                <div class="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4 text-center">
                    <center><span class="tituloCases">Cases de sucesso</span></center>
                </div>
                <div class="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4 align-itens-center text-center">
                    
                </div>
                <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 justify-content-center text-center">
                    <span class="text-sub">
                        Os resultados positivos dos nossos clientes comprovam a eficiência da Mercado<br>
                        Binário em transformar a comunicação digital da sua empresa.

                        <p>Conheça alguns cases:</p>
                    </span>
                </div>
                <div class="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4 d-flex justify-content-center" v-for="casesucesso in cases" :key="casesucesso.id">
                        <img class="cases-sucesso" :src="casesucesso.imagem">
                </div>
                 <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 d-flex justify-content-center pt-5 pb-5">
                   <a href="https://api.whatsapp.com/send/?phone=5541987230882&text=Gostaria+de+informa%C3%A7%C3%B5es+sobre+como+usar+IA+no+marketing+e+vendas" target="_blank"><BotaoSite calltoaction="SOLICITAR CONTATO DA MB"/></a>
                </div>
            </div>
            </div>
  
        </div>

        <div class="SecaoDoze mobile">
            <div class="container">
            <div class="row d-flex">
                <div class="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4 text-center">
                    
                </div>
                <div class="col-12 col-md-4 col-sm-12 col-lg-4 col-xl-4 text-center">
                    <center><span class="tituloCases">Cases de sucesso</span></center>
                </div>
                <div class="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4 align-itens-center text-center">
                    
                </div>
                <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 justify-content-center text-center">
                    <span class="text-sub">
                        Os resultados positivos dos nossos clientes comprovam a eficiência da Mercado
                        Binário em transformar a comunicação digital da sua empresa.

                        <p>Conheça alguns cases:</p>
                    </span>
                </div>
                <div class="col-12 col-md-4 col-sm-4 col-lg-4 col-xl-4 d-flex justify-content-center" v-for="casesucesso in cases" :key="casesucesso.id">
                        <img class="cases-sucesso" :src="casesucesso.imagem">
                </div>
                 <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 d-flex justify-content-center pt-5 pb-5">
                   <BotaoSite @click="mostrar" calltoaction="SOLICITAR CONTATO DA MB"/>
                </div>
            </div>
            </div>
        </div>
        <Popup 
      :show="showDialog"
      titulo="Preencha o formulário para falar com nossos consultores"
      :cancel="cancel"
    />

</template>

<script>
import BotaoSite from '@/components/BotaoSite.vue'
import Popup from '@/components/Popup.vue'
export default {
    name: 'SecaoDoze',
    data: () => ({
        cases: [
            {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/panelinhafit.svg'},
            {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/be.svg'},
            {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/plastitape.svg'}
        ],
        showDialog: false
    }),
    props: [
    'mostrar'
  ],
  methods: {
    cancel () {
        this.showDialog = false;
      },
    mostrar () {
      this.showDialog = true
    }
  },
    components: {
            BotaoSite,
            Popup
        }

}
</script>

<style scoped>

@media (max-width: 2000px) { 

    .desktop {
    display: block;
}

.mobile {
  display: none;
}

 .SecaoDoze {
    height: auto;
    background-image: url('@/assets/img/casesdesucesso/bgrodape.jpg');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    padding: 100px 0px 50px 0px;
}
span.tituloCases {
    background: #F37163;
    margin: 0px 0px;
    display: block;
    padding: 4px;
    border-radius: 7px;
    font-size: 33px;
    line-height: 34px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 50px;
    width: 100%;
}

span.text-sub {
    color: #fff;
    font-size: 20px;
    line-height: 34px;
    font-weight: 500;
}
img.cases-sucesso {
    max-width: 289px;
    padding: 50px 0px 0px 0px;
}

button.botaoPadrao {
    background-color: #00C02A;
    width: 387px;
    height: 48px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 19px;
    transition: .2s;
}
button.botaoPadrao:hover {
    background-color: #00C02A;
    width: 387px;
    height: 48px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 19px;
    transition: .2s;
}

 }

@media (max-width: 1440px) { 
  .SecaoDoze {
    height: auto;
    background-image: url('@/assets/img/casesdesucesso/bgrodape.jpg');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    padding-bottom: 53px;
}

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 1399px) { 

  span.tituloCases {
    background: #F37163;
    margin: 0px 0px;
    display: block;
    padding: 4px;
    border-radius: 7px;
    font-size: 33px;
    line-height: 34px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 50px;
    width: 100%;
}

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 1199.98px) { 

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 1024px) { 

  span.tituloCases {
    background: #F37163;
    margin: 0px 34px;
    display: block;
    padding: 4px;
    border-radius: 7px;
    font-size: 30px;
    line-height: 34px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 50px;
    width: 100%;
}

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 991.98px) { 

  .SecaoDoze {
    height: auto;
    background-image: url('@/assets/img/casesdesucesso/bgrodape.jpg');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    padding: 100px 0px 50px 0px;
}

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

span.tituloCases {
    background: #F37163;
    margin: 0px 34px;
    display: block;
    padding: 4px;
    border-radius: 7px;
    font-size: 21px;
    line-height: 34px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 50px;
    width: 220px;
}

span.text-sub {
    color: #fff;
    font-size: 17px;
    line-height: 34px;
    font-weight: 500;
}

img.cases-sucesso {
    max-width: 272px;
    padding: 50px 0px 0px 0px;
}

 }

@media (max-width: 768px) { 

  span.text-sub {
    color: #fff;
    font-size: 14px;
    line-height: 34px;
    font-weight: 500;
}

 .desktop {
    display: none;
}

.mobile {
  display: block;
}

 }

@media (max-width: 575.98px) { 

  .SecaoDoze {
    height: auto;
    background-image: url('@/assets/img/casesdesucesso/bgrodape.jpg');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    padding: 100px 0px 50px 0px;
}

 .desktop {
    display: none;
}

.mobile {
  display: block;
}
span.tituloCases {
    background: #F37163;
    margin: 0px 34px;
    display: block;
    padding: 4px;
    border-radius: 7px;
    font-size: 26px;
    line-height: 34px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 50px;
}

 }

@media (max-width: 360px) { 

  .SecaoDoze {
    height: auto;
    background-image: url('@/assets/img/casesdesucesso/bgrodape.jpg');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    padding: 100px 0px 50px 0px;
}

    .desktop {
    display: none;
}

.mobile {
  display: block;
}
span.tituloCases {
    background: #F37163;
    margin: 0px 34px;
    display: block;
    padding: 4px;
    border-radius: 7px;
    font-size: 26px;
    line-height: 34px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 50px;
}
 }
@media (max-width: 320px) { 

  .SecaoDoze {
    height: auto;
    background-image: url('@/assets/img/casesdesucesso/bgrodape.jpg');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    padding: 100px 0px 50px 0px;
}

.desktop {
    display: none;
}

.mobile {
  display: block;
}
span.tituloCases {
    background: #F37163;
    margin: 0px 34px;
    display: block;
    padding: 4px;
    border-radius: 7px;
    font-size: 26px;
    line-height: 34px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 50px;
    width: 270px;
    margin-left: 12px;
}
span.tituloParcerias {
    text-transform: uppercase;
    font-size: 19px;
    font-weight: 700;
    line-height: 39px;
    color: #fff;
    margin: 29px 0px;
    display: block;
}

 }


</style>