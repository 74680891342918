<template>
  <div class="QuintaSecao desktop">
    <div class="container">
      <div class="row row-programs">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <span class="tituloQuinta">
              Faça da internet a sua<br> vendedora campeã!
          </span>
          <p class="subtituloquinta">
            Multiplique as vendas e encontre novos clientes todos os dias. 
          </p>
        </div>
        <div class="col-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 align-self-center gy-5" id="parceiros" v-for="net in internet" :key="net.id">
        <img :src=net.imagem>
        </div>
      </div>
    </div>
  </div>

  <div class="QuintaSecao mobile">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <span class="tituloQuinta">
              Faça da internet a sua<br> vendedora campeã!
          </span>
          <p class="subtituloquinta">
            Multiplique as vendas e encontre novos clientes todos os dias. 
          </p>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" v-for="nets in internetmobile" :key="nets.id">
            <center><img class="mobiimg" :src=nets.imagemobile></center>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'QuintaSecao',
  data: () => ({
    internet: [
      {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/parceiros/google.svg'},
      {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/parceiros/olist.svg'},
      {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/parceiros/rdstation.svg'},
      {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/parceiros/tiktok.svg'},
      {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/parceiros/meta2.svg'},
    ],
    internetmobile: [
      {imagemobile: 'https://aumentar-vendas.mercadobinario.com.br/imagens/parceiros/google.svg'},
      {imagemobile: 'https://aumentar-vendas.mercadobinario.com.br/imagens/parceiros/olist.svg'},
      {imagemobile: 'https://aumentar-vendas.mercadobinario.com.br/imagens/parceiros/rdstation.svg'},
      {imagemobile: 'https://aumentar-vendas.mercadobinario.com.br/imagens/parceiros/tik.png'},
      {imagemobile: 'https://aumentar-vendas.mercadobinario.com.br/imagens/parceiros/meta2.svg'},
    ],
  }),
  components: {
  
  }
}
</script>

<style scoped>


/* media css */

@media (max-width: 2000px) { 

  #parceiros {
    margin: 0px 20px;
  }

  .row-programs .col-program:hover {
  margin-top: -10px;
  background-color: #fafafa;
  transition: all 0.4s ease-in-out;
  zoom: 1.1;
}

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

    span.tituloQuinta {
    font-size: 36px;
    line-height: 46px;
    font-weight: 900;
    text-transform: uppercase;
    display: block;
    text-align: center;
    background: linear-gradient(to right, #491C77 0%, #1D4E88 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

p.subtituloquinta {
    font-size: 20px;
    line-height: 25px;
    display: block;
    text-align: center;
    padding: 30px 0px;
}

.QuintaSecao {
    height: 1000px;
    background-image: url('@/assets/img/5/curvafundo.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center left;
    padding: 0px 0px 100px 0px;
    z-index: 1;
    position: relative;
}
img.vetor {
    padding: 100px 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 803px;
    height: auto;
}
 }

@media (max-width: 1440px) { 

  #parceiros {
    margin: 0px 20px;
  }

  .QuintaSecao {
    height: 1000px;
    background-image: url('@/assets/img/5/curvafundo.png');
    background-repeat: no-repeat;
    background-size: 45%;
    background-position: center left;
    margin-top: 0px;
    padding-top: 40px;
}

  .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

    img.vetor {
    padding: 100px 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 803px;
    height: auto;
}

img.multiplique {
    width: 361px;
    height: 354px;
}
 }

@media (max-width: 1399px) { 

  #parceiros {
    margin: 0px 10px;
  }


  .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

 }

@media (max-width: 1199.98px) { 

  div#parceiros img {
    height: auto;
    width: 100%;
}

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

 }

@media (max-width: 1024px) { 

  div#parceiros img {
    height: auto;
    width: 100%;
}

  .QuintaSecao {
    height: 1000px;
    background-image: url('@/assets/img/5/curvafundo.png');
    background-repeat: no-repeat;
    background-size: 39%;
    background-position: center left;
    margin-top: 0px;
    padding-top: 40px;
}

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

 }

@media (max-width: 991.98px) { 

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }


 }

@media (max-width: 768px) { 

  .QuintaSecao {
    height: auto;
    background-image: url('@/assets/img/6/06.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    padding: 100px 0px;
    z-index: 1;
    position: relative;
}

img.vetor {
    padding: 50px 0px 0px 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    height: auto;
}

  img.multiplique {
    width: 361px;
    height: auto;
}

 .desktop {
    display: none;
}

.mobile {
  display: block;
}
img.mobiimg {
    margin: 20px;
    width: 50%;
}

 }

@media (max-width: 575.98px) { 
  img.mobiimg {
    margin: 20px;
}

  .QuintaSecao {
    height: auto;
    background-image: url('');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    padding: 90px 0px 50px 0px;
    margin-top: 50px;
}

 .desktop {
    display: none;
}

.mobile {
  display: block;
}
span.tituloQuinta {
    font-size: 23px;
    line-height: 31px;
    font-weight: 900;
    text-transform: uppercase;
    display: block;
    text-align: center;
    background: linear-gradient(to right, #491C77 0%, #1D4E88 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
img.vetor {
    padding: 100px 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

img.multiplique {
    width: 100%;
}
 }

@media (max-width: 360px) { 

  img.mobiimg {
    margin: 20px;
    width: 50%;
}

  .QuintaSecao {
    height: auto;
    background-image: url('');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    padding: 90px 0px 50px 0px;
}

    .desktop {
    display: none;
}

.mobile {
  display: block;
}
span.tituloQuinta {
    font-size: 23px;
    line-height: 31px;
    font-weight: 900;
    text-transform: uppercase;
    display: block;
    text-align: center;
    background: linear-gradient(to right, #491C77 0%, #1D4E88 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
img.vetor {
    padding: 100px 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
img.multiplique {
    width: 100%;
}
 }
@media (max-width: 320px) { 

  img.mobiimg {
    margin: 20px;
    width: 50%;
}

  .QuintaSecao {
    height: auto;
    background-image: url('');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    padding: 90px 0px 50px 0px;
}

.desktop {
    display: none;
}

.mobile {
  display: block;
}
span.tituloQuinta {
    font-size: 23px;
    line-height: 31px;
    font-weight: 900;
    text-transform: uppercase;
    display: block;
    text-align: center;
    background: linear-gradient(to right, #491C77 0%, #1D4E88 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
img.vetor {
    padding: 100px 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}
img.multiplique {
    width: 100%;
}
 }

</style>