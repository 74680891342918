<template>
  <div class="SegundaSecao desktop">
      <div class="container-fluid">
        <div class="row d-flex align-items-center">
          <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12" style="user-select: none;">
            <img width="100%" src="@/assets/img/2/titulo.webp">
             <!--<span class="nosabemos">Nós sabemos que para obter lucro é preciso trabalhar com</span>
             <span class="atencao">atenção especial aos desejos de cada público.</span>-->
          </div>
          <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12">
              <span class="subtitulosegunda" >
                  Conhecendo melhor as personas, você encontra quem paga mais pelo que a sua<br> empresa oferece, investe de forma assertiva e otimiza os resultados.
              </span>
          </div>
          <div class="col-12 col-md-6 col-sm-6 col-lg-4 col-xl-4">
            
          </div>
          <div class="col-12 col-md-6 col-sm-6 col-lg-8 col-xl-8">
              <span class="como">
                Trabalhando com as melhores ferramentas de<br> marketing  digital.
                <p class="comosub">
                  E é aí que nós, da MB, entramos.
                </p>
              </span>
          </div>
        </div>
      </div>
  </div>

  <div class="SegundaSecao mobile">
      <div class="container-fluid">
        <div class="row d-flex align-items-center">
          <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 borda">
             <span class="titulomobile">Nós sabemos que para<br> obter lucro é preciso<br> trabalhar com atenção<br> especial aos desejos de<br> cada público.</span>
          </div>
          <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 bg text-center">
              <span class="subtitulosegunda">
                  Conhecendo melhor as personas, você encontra quem paga mais pelo que a sua<br> empresa oferece, investe de forma assertiva e otimiza os resultados.
              </span>
          </div>
          <div class="col-12 col-md-6 col-sm-12 col-lg-4 col-xl-4">
              <center><img id="como" src="@/assets/img/2/como.svg"></center>
          </div>
          <div class="col-12 col-md-6 col-sm-12 col-lg-8 col-xl-8 text-center">
              <span class="como">
                Trabalhando com as melhores ferramentas de<br> marketing digital.
                <p class="comosub">
                  E é aí que nós, da MB, entramos.
                </p>
              </span>
          </div>
        </div>
      </div>
  </div>

</template>

<script>
// @ is an alias to /src


export default {
  name: 'SegundaSecao',
  components: {
  
  }
}
</script>

<style scoped>


/* media css */

@media (max-width: 2000px) { 

  span.atencao {
    text-align: center;
    display: block;
    background-image: url('@/assets/img/2/Rectangleatencao.svg');
    background-position: center;
    padding: 5px 0px;
    background-size: 52%;
    font-size: 36px;
    font-weight: 900;
    line-height: 57px;
    color: #fff;
    background-repeat: no-repeat;
}

span.atencao::after {

  content: "";
    position: absolute;
    top: 218px;
    right: 0%;
    display: block;
    width: 466px;
    height: 1px;
    border: 1px solid #1d4e88;

}
  span.nosabemos {
    text-align: center;
    display: block;
    background-image: url('@/assets/img/2/Rectangle.svg');
    background-position: center;
    padding: 13px 0px;
    background-size: 64%;
    font-size: 36px;
    font-weight: 900;
    line-height: 57px;
    color: #fff;
    background-repeat: no-repeat;
}
span.nosabemos::before {
    content: "";
    position: absolute;
    top: 143px;
    left: 0%;
    display: block;
    width: 352px;
    height: 1px;
    border: 1px solid #1d4e88;
}

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

    /* 2 */

    .SegundaSecao {
    height: 840px;
    padding: 100px 0px;
    background-image: url('@/assets/img/2/02.png');
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 2;
    position: relative;
}

span.subtitulosegunda {
    text-align: center;
    display: block;
    font-size: 20px;
    line-height: 33px;
    font-weight: 400;
    padding-top: 40px;
    padding-bottom: 80px;
}

span.como {
    font-size: 20px;
    line-height: 33px;
    font-weight: 700;
    text-transform: uppercase;
    font-weight: 900;
    top: 0px;
    position: relative;
}
p.comosub {
    padding-top: 30px;
    color: #1D4E88;
    font-size: 20px;
    line-height: 33px;
    font-weight: 900;
}

img.lucro {
    width: 100%;
}


 }

 @media (max-width: 1789px) { 

      span.nosabemos {
    text-align: center;
    display: block;
    background-image: url('@/assets/img/2/Rectangle.svg');
    background-position: 50%;
    padding: 13px 0;
    background-size: 84%;
    font-size: 36px;
    font-weight: 900;
    line-height: 57px;
    color: #fff;
    background-repeat: no-repeat;
}

span.nosabemos:before {
    content: "";
    position: absolute;
    top: 143px;
    left: 0;
    display: block;
    width: 337px;
    height: 1px;
    border: 1px solid #1d4e88;
    z-index: -1;
}

span.atencao {
    text-align: center;
    display: block;
    background-image: url('@/assets/img/2/Rectangleatencao.svg');
    background-position: center;
    padding: 5px 0px;
    background-size: 63%;
    font-size: 36px;
    font-weight: 900;
    line-height: 57px;
    color: #fff;
    background-repeat: no-repeat;
}

span.atencao::after {

  content: "";
    position: absolute;
    top: 218px;
    right: 0%;
    display: block;
    width: 466px;
    height: 1px;
    border: 1px solid #1d4e88;
        z-index: -1;

}
  
 }
@media (max-width: 1440px) { 

  span.nosabemos {
    text-align: center;
    display: block;
    background-image: url('@/assets/img/2/Rectangle.svg');
    background-position: center;
    padding: 0px 0px;
    background-size: 70%;
    font-size: 28px;
    font-weight: 900;
    line-height: 57px;
    color: #fff;
    background-repeat: no-repeat;
}
span.nosabemos::before {
    content: "";
    position: absolute;
    top: 130px;
    left: 0%;
    display: block;
    width: 235px;
    height: 1px;
    border: 1px solid #1d4e88;
}

span.atencao {
    text-align: center;
    display: block;
    background-image: url('@/assets/img/2/Rectangleatencao.svg');
    background-position: center;
    padding: 5px 0px;
    background-size: 54%;
    font-size: 28px;
    font-weight: 900;
    line-height: 57px;
    color: #fff;
    background-repeat: no-repeat;
}

span.atencao::after {

  content: "";
    position: absolute;
    top: 190px;
    right: 0%;
    display: block;
    width: 349px;
    height: 1px;
    border: 1px solid #1d4e88;

}

  .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

    span.como {
    font-size: 20px;
    line-height: 33px;
    font-weight: 700;
    text-transform: uppercase;
    font-weight: 900;
    left: 146px;
    display: block;
    position: relative;
    top: 13px;
}

img.lucro {
    width: 100%;
}

 }

@media (max-width: 1399px) { 

  span.nosabemos {
    text-align: center;
    display: block;
    background-image: url('@/assets/img/2/Rectangle.svg');
    background-position: center;
    padding: 0px 0px;
    background-size: 85%;
    font-size: 28px;
    font-weight: 900;
    line-height: 57px;
    color: #fff;
    background-repeat: no-repeat;
    margin-bottom: 10px;
}
span.nosabemos::before {
    content: "";
    position: absolute;
    top: 130px;
    left: 0%;
    display: block;
    width: 224px;
    height: 1px;
    border: 1px solid #1d4e88;
        z-index: -1;
}

span.atencao {
    text-align: center;
    display: block;
    background-image: url('@/assets/img/2/Rectangleatencao.svg');
    background-position: center;
    padding: 5px 0px;
    background-size: 69%;
    font-size: 28px;
    font-weight: 900;
    line-height: 57px;
    color: #fff;
    background-repeat: no-repeat;
}

span.atencao::after {

  content: "";
    position: absolute;
    top: 190px;
    right: 0%;
    display: block;
    width: 300px;
    height: 1px;
    border: 1px solid #1d4e88;
        z-index: -1;

}

  .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }
    span.como {
    font-size: 20px;
    line-height: 33px;
    font-weight: 700;
    text-transform: uppercase;
    font-weight: 900;
    left: 155px;
    display: block;
    position: relative;
    top: 35px;
}

 }

@media (max-width: 1199.98px) { 

  span.nosabemos {
    text-align: center;
    display: block;
    background-image: url('@/assets/img/2/Rectangle.svg');
    background-position: center;
    padding: 0px 0px;
    background-size: 92%;
    font-size: 28px;
    font-weight: 900;
    line-height: 57px;
    color: #fff;
    background-repeat: no-repeat;
}
span.nosabemos::before {
    content: "";
    position: absolute;
    top: 130px;
    left: 0%;
    display: block;
    width: 90px;
    height: 1px;
    border: 1px solid #1d4e88;
}

span.atencao {
    text-align: center;
    display: block;
    background-image: url('@/assets/img/2/Rectangleatencao.svg');
    background-position: center;
    padding: 5px 0px;
    background-size: 73%;
    font-size: 28px;
    font-weight: 900;
    line-height: 57px;
    color: #fff;
    background-repeat: no-repeat;
}

span.atencao::after {

  content: "";
    position: absolute;
    top: 200px;
    right: 0%;
    display: block;
    width: 174px;
    height: 1px;
    border: 1px solid #1d4e88;
    z-index: -1;

}

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

     span.como {
    font-size: 18px;
    line-height: 33px;
    text-transform: uppercase;
    font-weight: 900;
    left: 225px;
    display: block;
    position: relative;
    top: 38px;
    word-break: break-word;
    margin-right: 222px;
}

 }

@media (max-width: 1024px) { 


span.nosabemos {
    text-align: center;
    display: block;
    background-image: url('@/assets/img/2/Rectangle.svg');
    background-position: center;
    padding: 0px 0px;
    background-size: 88%;
    font-size: 26px;
    font-weight: 900;
    line-height: 57px;
    color: #fff;
    background-repeat: no-repeat;
}
span.nosabemos::before {
    content: "";
    position: absolute;
    top: 130px;
    left: 0%;
    display: block;
    width: 78px;
    height: 1px;
    border: 1px solid #1d4e88;
}

span.atencao {
    text-align: center;
    display: block;
    background-image: url('@/assets/img/2/Rectangleatencao.svg');
    background-position: center;
    padding: 5px 0px;
    background-size: 71%;
    font-size: 26px;
    font-weight: 900;
    line-height: 57px;
    color: #fff;
    background-repeat: no-repeat;
}

span.atencao::after {

  content: "";
    position: absolute;
    top: 190px;
    right: 0%;
    display: block;
    width: 173px;
    height: 1px;
    border: 1px solid #1d4e88;

}

  .SegundaSecao {
    height: 723px;
    padding: 100px 0px;
    background-image: url('@/assets/img/2/02.png');
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 2;
    position: relative;
    background-position: -11%;
}

     span.como {
    font-size: 18px;
    line-height: 33px;
    text-transform: uppercase;
    font-weight: 900;
    left: 153px;
    display: block;
    position: relative;
    top: 0px;
    word-break: break-word;
    margin-right: 157px;
}

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

 

 }

@media (max-width: 991.98px) { 

  span.nosabemos {
    text-align: center;
    display: block;
    background-image: url('@/assets/img/2/Rectangle.svg');
    background-position: center;
    padding: 0px 0px;
    background-size: 93%;
    font-size: 22px;
    font-weight: 900;
    line-height: 57px;
    color: #fff;
    background-repeat: no-repeat;
}
span.nosabemos::before {
    content: "";
    position: absolute;
    top: 130px;
    left: 0%;
    display: block;
    width: 114px;
    height: 1px;
    border: 1px solid #1d4e88;
}

span.atencao {
    text-align: center;
    display: block;
    background-image: url('@/assets/img/2/Rectangleatencao.svg');
    background-position: center;
    padding: 5px 0px;
    background-size: 89%;
    font-size: 25px;
    font-weight: 900;
    line-height: 57px;
    color: #fff;
    background-repeat: no-repeat;
}

span.atencao::after {

  content: "";
    position: absolute;
    top: 190px;
    right: 0%;
    display: block;
    width: 85px;
    height: 1px;
    border: 1px solid #1d4e88;

}


  .SegundaSecao {
    height: 695px;
    padding: 100px 0px;
    background-image: url('@/assets/img/2/02.png');
    background-size: contain;
    background-repeat: no-repeat;
}

  span.como {
    font-size: 14px;
    line-height: 29px;
    text-transform: uppercase;
    font-weight: 900;
    left: -6px;
    display: block;
    position: relative;
    top: -41px;
    margin-right: 44px;
}

p.comosub {
    padding-top: 30px;
    color: #1D4E88;
    font-size: 14px;
    line-height: 33px;
    font-weight: 900;
}
    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

 }

@media (max-width: 767.98px) { 

  .SegundaSecao {
    height: auto;
    padding: 290px 0px 70px 0px;
    background-image: url('');
    background-size: contain;
    background-repeat: no-repeat;
}

.col-12.col-md-12.col-sm-12.col-lg-12.col-xl-12.borda {
    background: linear-gradient(270deg, #491C77 0%, #1D4E88 100%);
    border-radius: 0px 30px 0px 0px;
}

span.subtitulosegunda {
    text-align: center;
    display: block;
    font-size: 18px;
    line-height: 33px;
    font-weight: 400;
    padding-top: 40px;
    padding-bottom: 50px;
}

span.titulomobile {
    font-size: 24px;
    line-height: 29px;
    padding: 20px 0px;
    display: block;
    text-align: center;
    color: #fff;
    font-weight: 900;
}

span.como {
    font-size: 20px;
    line-height: 33px;
    text-transform: uppercase;
    font-weight: 900;
    left: 33px;
    display: block;
    position: relative;
    top: 33px;
    text-align: center;
}

 .desktop {
    display: none;
}

.mobile {
  display: block;
}

span#tituloterceira {
    display: block;
    text-align: center;
    color: var(--branco);
    font-weight: 900;
    font-size: 28px;
    line-height: 37px;
}

p.comosub {
    padding-top: 30px;
    color: #1D4E88;
    font-size: 20px;
    line-height: 33px;
    font-weight: 900;
    display: block;
    position: relative;
    margin-bottom: 47px;
}

img#como {
    display: block;
    position: relative;
    bottom: 0px;
}

 }

@media (max-width: 575.98px) { 

 .desktop {
    display: none;
}

.mobile {
  display: block;
}

.SegundaSecao {
    height: auto;
    padding: 100px 0px;
    background-image: url('');
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 338px;
}
.col-12.col-md-12.col-sm-12.col-lg-12.col-xl-12.borda {
    background: linear-gradient(270deg, #491C77 0%, #1D4E88 100%);
    border-radius: 0px 30px 0px 0px;
}
span.titulomobile {
    font-size: 24px;
    line-height: 27px;
    color: #fff;
    text-align: center;
    display: block;
    font-weight: 900;
        padding: 20px 0px;
}

span.subtitulosegunda {
    text-align: center;
    display: block;
    font-size: 20px;
    line-height: 26px;
    font-weight: 400;
    padding-top: 40px;
    padding-bottom: 0px;

}
span.como {
    font-size: 20px;
    line-height: 33px;
    text-transform: uppercase;
    font-weight: 900;
    text-align: center;
}
p.comosub {
    padding-top: 30px;
    color: #1D4E88;
    font-size: 20px;
    line-height: 33px;
    font-weight: 900;
    display: block;
    position: relative;
    margin-bottom: 47px;
}

 }

 @media (max-width: 414px) { 

   .SegundaSecao {
    height: auto;
    padding: 100px 0px;
    background-image: url('@/assets/img/2/bgmobile.png');
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 284px;
    background-position: right;
    padding-top: 50px;
}


 }

@media (max-width: 360px) { 

    .desktop {
    display: none;
}

.mobile {
  display: block;
}

.SegundaSecao {
    height: auto;
    padding: 100px 0px;
    background-image: url('@/assets/img/2/bgmobile.png');
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 246px;
    background-position: right;
    padding-top: 50px;
}
.col-12.col-md-12.col-sm-12.col-lg-12.col-xl-12.borda {
    background: linear-gradient(270deg, #491C77 0%, #1D4E88 100%);
    border-radius: 0px 30px 0px 0px;
}
span.titulomobile {
    font-size: 24px;
    line-height: 27px;
    color: #fff;
    text-align: center;
    display: block;
    font-weight: 900;
        padding: 20px 0px;
}
span.subtitulosegunda {
    text-align: center;
    display: block;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    padding-top: 40px;
    padding-bottom: 0px;
}

span.como {
    font-size: 20px;
    line-height: 33px;
    text-transform: uppercase;
    font-weight: 900;
    text-align: center;
    left: 0px;
}

p.comosub {
    padding-top: 30px;
    color: #1D4E88;
    font-size: 20px;
    line-height: 33px;
    font-weight: 900;
    display: block;
    position: relative;
    margin-bottom: 47px;
}

 }
@media (max-width: 320px) { 

.desktop {
    display: none;
}

.mobile {
  display: block;
}

.SegundaSecao {
    height: auto;
    padding: 100px 0px;
    background-image: url('@/assets/img/2/bgmobile.png');
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 255px;
    padding-top: 50px;
}
.col-12.col-md-12.col-sm-12.col-lg-12.col-xl-12.borda {
    background: linear-gradient(270deg, #491C77 0%, #1D4E88 100%);
    border-radius: 0px 30px 0px 0px;
}
span.titulomobile {
    font-size: 23px;
    line-height: 29px;
    color: #fff;
    text-align: center;
    display: block;
    font-weight: 900;
        padding: 20px 0px;
}

span.subtitulosegunda {
    text-align: center;
    display: block;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    padding-top: 40px;
    padding-bottom: 0px;
}
span.como {
    font-size: 20px;
    line-height: 33px;
    text-transform: uppercase;
    font-weight: 900;
    text-align: center;
}

p.comosub {
    padding-top: 30px;
    color: #1D4E88;
    font-size: 20px;
    line-height: 33px;
    font-weight: 900;
    display: block;
    position: relative;
    margin-bottom: 47px;
}
img#como {
    display: block;
    position: relative;
    bottom: 0px;
    width: 100%;
}

 }

</style>