<template>
<Suspense>
  <template #default>
    <router-view/>
  </template>
  <template #fallback>
    <div>Carregando...</div>
  </template>
  
</Suspense>
  
</template>

<style>

</style>
