<template>
  <div class="DecimaSecao desktop">
    <div class="container">
      <div class="row d-flex align-items-center">
        <div class="col-12 col-md-6 col-sm-6 col-lg-6 col-xl-6 align-itens-center">
            <span class="tituloDecima text-break">Vender mais é simples<br> com o Método MB</span><br>
            <p class="sub-text text-break">
                A MB está entre os 3% das agências que geram resultados reais<br> de vendas e são reconhecidas pelo Google como Partner Premier. <br><br>

                Conte com uma equipe certificada pelas principais plataformas de relacionamento como Facebook e RD Station, e dê um grande passo para melhorar a comunicação da sua empresa.
            </p>
            <!--<BotaoSite @click="mostrar" class="callDecima" calltoaction="AGENDAR CONSULTORIA GRATUITA" />-->
        </div>
        <div class="col-12 col-md-6 col-sm-6 col-lg-6 col-xl-6 d-flex justify-content-end">
            <div class="card">
                    <div class="card-body">
                        <h5 class="card-title text-break">Complete os campos abaixo e<br> garanta o seu horário</h5>
                        <Form 
                        textSubmit="Agendar consultoria gratuita" 
                        
                        />
                    </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div class="DecimaSecao mobile">
    <div class="container">
      <div class="row d-flex align-items-center">
        <div class="col-12 col-md-6 col-sm-12 col-lg-6 col-xl-6 align-itens-center">
            <span class="tituloDecima text-break">Vender mais é simples com o Método MB</span><br>
            <p class="sub-text text-break">
                A MB está entre os 3% das agências que geram resultados reais de vendas e são reconhecidas pelo Google como Partner Premier. <br><br>

                Conte com uma equipe certificada pelas principais plataformas de relacionamento como Facebook e RD Station, e dê um grande passo para melhorar a comunicação da sua empresa.
            </p>
            <center><!--<BotaoSite @click="mostrar" class="callDecima" calltoaction="AGENDAR CONSULTORIA GRATUITA" />--></center>
        </div>
        <div class="col-12 col-md-6 col-sm-12 col-lg-6 col-xl-6 d-flex justify-content-center">
            <div class="card">
                    <div class="card-body">
                        <h5 class="card-title text-break">Complete os campos abaixo e<br> garanta o seu horário</h5>
                         <Form 
                        textSubmit="Agendar consultoria gratuita" 
                        
                        />
                    </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  <Popup 
      :show="showDialog"
      titulo="Preencha o formulário para falar com nossos consultores"
      :cancel="cancel"
    />
</template>

<script>
import Popup from '@/components/Popup.vue'
// @ is an alias to /src
import BotaoSite from './BotaoSite.vue';
import Form from './Form.vue';
export default {
  name: 'DecimaSecao',
  data: () => ( {
    showDialog: false
  }),
  props: [
    'mostrar'
  ],
  methods: {
    cancel () {
        this.showDialog = false;
      },
    mostrar () {
      this.showDialog = true
    }
  },
  components: {
    BotaoSite,
    Form,
    Popup
}
}
</script>

<style scoped>


/* media css */

.textobranco {
  color: #fff;
}

.DecimaSecao {
    height: auto;
    background-image: url('@/assets/img/10/Group-22.jpg');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    padding: 100px 0px;
}


@media (max-width: 2000px) { 

    .desktop {
    display: block;
}

.mobile {
  display: none;
}

button.botaoPadrao.callDecima {
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    padding: 0px 10px;
    margin-top: 30px;
    width: 330px;
    height: 48px;
}

span.tituloDecima {
    font-size: 28px;
    line-height: 39px;
    color: #fff;
    font-weight: 900;
    text-transform: uppercase;
}

p.sub-text {
    font-size: 16px;
    line-height: 29px;
    font-weight: 400;
    color: #fff;
    padding-top: 30px;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 26px;
    width: 460px;
    height: auto;
    padding: 15px 37px;
}

h5.card-title {
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    text-align: center;
    padding-bottom: 13px;
    color: #000;
    font-family: 'Montserrat';
}

 }

@media (max-width: 1440px) { 

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 1399px) { 

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 1199.98px) { 

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 1024px) { 

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 991.98px) { 

  span.tituloDecima {
    font-size: 24px;
    line-height: 39px;
    color: #fff;
    font-weight: 900;
    text-transform: uppercase;
}

 .desktop {
    display: block;
}

.mobile {
  display: none;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 26px;
    width: 460px;
    height: auto;
    padding: 15px 0px;
}

 }

@media (max-width: 768px) { 

  button.botaoPadrao.callDecima {
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    padding: 0px 10px;
    margin-top: 30px;
    width: 325px;
    height: 48px;
    margin-bottom: 30px;
}
button.botaoPadrao.callDecima:hover {
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    padding: 0px 10px;
    margin-top: 30px;
    width: 325px;
    height: 48px;
    margin-bottom: 30px;
}
 .desktop {
    display: none;
}

.mobile {
  display: block;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 26px;
    width: 460px;
    height: auto;
    padding: 15px 12px;
}
span.tituloDecima[data-v-9de906f8] {
    font-size: 24px;
    line-height: 39px;
    color: #fff;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    display: block;
}
p.sub-text {
    font-size: 16px;
    line-height: 29px;
    font-weight: 400;
    color: #fff;
    padding-top: 30px;
    text-align: center;
}

 }

@media (max-width: 575.98px) { 

 .desktop {
    display: none;
}

.mobile {
  display: block;
}
span.tituloDecima {
    font-size: 28px;
    line-height: 39px;
    color: #fff;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
    display: block;
}
.DecimaSecao {
    height: auto;
    background-image: url('@/assets/img/10/Group-22.jpg');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    padding: 50px 0px;
}
p.sub-text {
    font-size: 16px;
    line-height: 29px;
    font-weight: 400;
    color: #fff;
    padding-top: 30px;
    text-align: center;
}
button.botaoPadrao.callDecima {
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    padding: 0px 10px;
    margin-top: 30px;
    width: 240px;
    height: 53px;
    margin-bottom: 50px;
}
button.botaoPadrao.callDecima:hover {
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    padding: 0px 10px;
    margin-top: 30px;
    width: 240px;
    height: 53px;
    margin-bottom: 50px;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 26px;
    width: 460px;
    height: auto;
    padding: 15px 12px;
}
 }

@media (max-width: 360px) { 

    .desktop {
    display: none;
}

.mobile {
  display: block;
}

span.tituloDecima {
    font-size: 28px;
    line-height: 39px;
    color: #fff;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
    display: block;
}
.DecimaSecao {
    height: auto;
    background-image: url('@/assets/img/10/Group-22.jpg');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    padding: 50px 0px;
}
p.sub-text {
    font-size: 16px;
    line-height: 29px;
    font-weight: 400;
    color: #fff;
    padding-top: 30px;
    text-align: center;
}
button.botaoPadrao.callDecima {
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    padding: 0px 10px;
    margin-top: 30px;
    width: 240px;
    height: 53px;
    margin-bottom: 50px;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 26px;
    width: 460px;
    height: auto;
    padding: 15px 12px;
}

 }
@media (max-width: 320px) { 

.desktop {
    display: none;
}

.mobile {
  display: block;
}

span.tituloDecima {
    font-size: 28px;
    line-height: 39px;
    color: #fff;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
    display: block;
}
.DecimaSecao {
    height: auto;
    background-image: url('@/assets/img/10/Group-22.jpg');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    padding: 50px 0px;
}
p.sub-text {
    font-size: 16px;
    line-height: 29px;
    font-weight: 400;
    color: #fff;
    padding-top: 30px;
    text-align: center;
}
button.botaoPadrao.callDecima {
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    padding: 0px 10px;
    margin-top: 30px;
    width: 240px;
    height: 53px;
    margin-bottom: 50px;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 26px;
    width: 460px;
    height: auto;
    padding: 15px 12px;
}
 }


</style>