<template>
    <div class="oitavaSecao">
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-lg-center">
                    <button class="quality" v-html="buttonQuality"></button>
                </div>
                 <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3" v-for="quality in qualidade" :key="quality.id">
                        <center><img style="display: block; marginTop: 100px" id="imgquality" :src="quality.imagemQuality"></center>
                        <p :style="estiloTitulo" v-html="quality.titulo"></p>
                        <p class="descricaoOitava" v-html="quality.descricao"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BotaoSite from './BotaoSite.vue';

export default {
    name: 'oitavaSecao',
    data: () => ({
        buttonQuality: 'NÚMEROS QUE COMPROVAM<br> NOSSA QUALIDADE',
        qualidade: [
            {imagemQuality: 'https://aumentar-vendas.mercadobinario.com.br/imagens/projetos.webp', titulo: '+ de <pan style="font-size:65px; font-weight:700; color:#fff;line-height:65px">300</span>', descricao: 'projetos entregues<br> com excelência'},
            {imagemQuality: 'https://aumentar-vendas.mercadobinario.com.br/imagens/ads.webp', titulo: '+ de <pan style="font-size:65px; font-weight:700; color:#fff;line-height:65px">100</span>', descricao: 'milhões de reais<br> gerenciados em<br> campanhas'},    
            {imagemQuality: 'https://aumentar-vendas.mercadobinario.com.br/imagens/google.webp', titulo: '<pan style="font-size:65px; font-weight:700; color:#fff;line-height:65px">1.8</span>', descricao: 'bilhão de<br> conversões<br> realizadas'},
            {imagemQuality: 'https://aumentar-vendas.mercadobinario.com.br/imagens/especialistas.webp', titulo: '+ de <pan style="font-size:65px; font-weight:700; color:#fff;line-height:65px">20</span>', descricao: 'especialistas em<br> suas áreas de<br> atuação'}
        ], 
        estiloTitulo: {
            color: '#fff',
            fontSize:45 + 'px',
            fontWeight:700,
            textAlign: "center",
            marginTop: 30 + 'px'
        }, 
        estiloDescricao: {
            color: '#fff',
            fontSize:24 + 'px',
            fontWeight:500,
            paddingTop: 20 + 'px',
            textAlign: "center",
            lineHeight: 27 + 'px'
        }, 
    }),
     components: {
    BotaoSite
}
}
</script>

<style scoped>

@media (max-width: 2000px) { 

    p.descricaoOitava {
    font-size: 24px;
    line-height: 33px;
    color: rgb(255, 255, 255);
    font-weight: 500;
    padding-top: 20px;
    text-align: center;
}

    .desktop {
    display: block;
}

.mobile {
  display: none;
}

.oitavaSecao {
    height: 730px;
    padding: 110px 0px;
    background-image: url('@/assets/img/8/bgmbform.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

button.quality {
    width: 478px;
    height: 85px;
    border-radius: 87px;
    font-size: 24px;
    line-height: 29px;
    color: #FD5536;
    font-weight: 900;
    border: 0px;
}
 }

@media (max-width: 1440px) { 
    p.descricaoOitava {
    font-size: 18px;
    line-height: 23px;
    color: rgb(255, 255, 255);
    font-weight: 500;
    padding-top: 20px;
    text-align: center;
}
  
 .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 1399px) { 
    p.descricaoOitava {
    font-size: 18px;
    line-height: 23px;
    color: rgb(255, 255, 255);
    font-weight: 500;
    padding-top: 20px;
    text-align: center;
}

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 1199.98px) { 

p.descricaoOitava {
    font-size: 18px;
    line-height: 23px;
    color: rgb(255, 255, 255);
    font-weight: 500;
    padding-top: 20px;
    text-align: center;
}
 .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 1024px) { 
    p.descricaoOitava {
    font-size: 18px;
    line-height: 23px;
    color: rgb(255, 255, 255);
    font-weight: 500;
    padding-top: 20px;
    text-align: center;
}

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

.oitavaSecao {
    height: auto;
    padding: 110px 0px;
    background-image: url('@/assets/img/8/bgmbform.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

 }

@media (max-width: 991.98px) { 

    button.quality {
    width: 478px;
    height: 85px;
    border-radius: 87px;
    font-size: 24px;
    line-height: 29px;
    color: #FD5536;
    font-weight: 900;
    border: 0px;
    left: 90px;
    position: relative;
}

    p.descricaoOitava {
    font-size: 18px;
    line-height: 23px;
    color: rgb(255, 255, 255);
    font-weight: 500;
    padding-top: 20px;
    text-align: center;
}

    .oitavaSecao {
    height: auto;
    padding: 110px 0px;
    background-image: url('@/assets/img/8/bgmbform.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

img#imgquality {
    width: 50%;
}

 }

@media (max-width: 768px) { 

    .oitavaSecao[data-v-2bbe7fa6] {
    height: auto;
    padding: 0px 0px 100px 0px;
    background-image: url('@/assets/img/8/bgmbform.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

button.quality {
    width: 478px;
    height: 85px;
    border-radius: 87px;
    font-size: 24px;
    line-height: 29px;
    color: #FD5536;
    font-weight: 900;
    border: 0px;
    margin-top: -42px;
    display: block;
    left: 20px;
    position: relative;
}

 .desktop {
    display: none;
}

.mobile {
  display: block;
}

p.descricaoOitava {
    font-size: 18px;
    line-height: 23px;
    color: rgb(255, 255, 255);
    font-weight: 500;
    padding-top: 20px;
    text-align: center;
}
img#imgquality {
    width: 50%;
}

 }

@media (max-width: 575.98px) { 

  .oitavaSecao {
    height: auto;
    padding: 0px 0px 110px 0px;
    background-image: url('@/assets/img/8/bgmbform.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

 .desktop {
    display: none;
}

.mobile {
  display: block;
}
button.quality {
    width: 366px;
    height: 139px;
    border-radius: 87px;
    font-size: 24px;
    line-height: 29px;
    color: #FD5536;
    font-weight: 900;
    border: 0px;
    margin-top: -52px;
    left: 0px;
}

button.botaoPadrao {
    background-color: #00C02A;
    width: 319px;
    height: 59px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 19px;
    transition: .2s;
}
button.botaoPadrao:hover {
    background-color: #00C02A;
    width: 319px;
    height: 59px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 19px;
    transition: .2s;
}

img#imgquality {
    width: 24%;
}

 }

@media (max-width: 360px) { 

    .desktop {
    display: none;
}

.mobile {
  display: block;
}
.oitavaSecao {
    height: auto;
    padding: 0px 0px 110px 0px;
    background-image: url('@/assets/img/8/bgmbform.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
button.quality {
    width: 366px;
    height: 139px;
    border-radius: 87px;
    font-size: 24px;
    line-height: 29px;
    color: #FD5536;
    font-weight: 900;
    border: 0px;
    margin-top: -52px;
}

button.botaoPadrao {
    background-color: #00C02A;
    width: 319px;
    height: 59px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 19px;
    transition: .2s;
}
button.botaoPadrao:hover {
    background-color: #00C02A;
    width: 319px;
    height: 59px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 19px;
    transition: .2s;
}
 }
@media (max-width: 320px) { 

.desktop {
    display: none;
}

.mobile {
  display: block;
}
.oitavaSecao {
    height: auto;
    padding: 0px 0px 110px 0px;
    background-image: url('@/assets/img/8/bgmbform.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
button.quality {
    width: 366px;
    height: 139px;
    border-radius: 87px;
    font-size: 24px;
    line-height: 29px;
    color: #FD5536;
    font-weight: 900;
    border: 0px;
    margin-top: -52px;
}

button.botaoPadrao {
    background-color: #00C02A;
    width: 319px;
    height: 59px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 19px;
    transition: .2s;
}
button.botaoPadrao:hover {
    background-color: #00C02A;
    width: 319px;
    height: 59px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 19px;
    transition: .2s;
}
 }


</style>