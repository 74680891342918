<template>
  <div class="rodape">
    <div class="container">
      <div class="row d-flex align-items-end align-items-md-end align-items-sm-center justify-content-center ">
        <div class="col-12 col-md-3 col-sm-6 col-lg-4 col-xl-4 d-flex justify-content-center align-items-md-end" v-for="(certificado, index) in certificados" :key="index">
                    <img v-if="index === 0" class="certificado certif" :src="certificado.imagem">
                    <a v-else-if="index === 1" :href="premier" target="_blank"><img class="partner certif" :src="certificado.imagem"></a>
                    <img v-else-if="index === 2" class="goldPartner certif" :src="certificado.imagem">
                    <a v-else-if="index === 3" :href="tray" target="_blank"><Center><img  class="mPartners certif" :src="certificado.imagem"></Center></a>
                    <img v-else-if="index === 4" class="tiktok" :src="certificado.imagem">
                    <img v-else-if="index === 5" class="meta" :src="certificado.imagem">
        </div>
        <div class="col-12 justify-content-center text-center text-white">
                 <span class="direitos" v-html="direitos"></span>
        </div>
        <span class="d-flex justify-content-end"><a class="gototop" href="#" alt="subir"><i class="bi bi-caret-up-fill"></i></a></span>
      </div>
    </div>
    <a href="https://api.whatsapp.com/send/?phone=5541987230882&text=Gostaria+de+informa%C3%A7%C3%B5es+sobre+como+usar+IA+no+marketing+e+vendas" target="_blank"><img id="whatsapp" src="@/assets/img/whatsapp.png"></a>
  </div>
</template>
<script>
export default {
  name: 'rodape',
  data: () => ( {
      certificados: [
          {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/abcom.svg'},
          {imagem: 'https://www.gstatic.com/partners/badge/images/2022/PremierBadgeClickable.svg'},
          {imagem: 'https://aumentar-vendas.mercadobinario.com.br/imagens/goldpartner.svg'},
      ], 
      premier: 'https://www.google.com/partners/agency?id=1674006423',
      tray: 'https://www.traycorp.com.br/parceiros/mercado-binario/',
      direitos: 'Desenvolvido pela Mercado Binário - Em busca do seu resultado ©2022'
  }),
  components: {

  }
}
</script>

<style scoped>


/* media css */

.textobranco {
  color: #fff;
}

.espaco {
  padding-top: 0%;
}

.rodape {
    height: auto;
    background-color: #2C2C2C;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    padding: 50px 0px;
}

@media (max-width: 2000px) { 

    a.gototop {
    color: #fff;
    position: relative;
    bottom: 0px;
    right: 0;
    display: block;
    font-size: 28px;
    padding: 8px;
    border-radius: 50px;
    background-color: #2a3f83;
    opacity: 0.5;
}
 a.gototop:hover {
    color: #fff;
    position: relative;
    bottom: 0px;
    right: 0;
    display: block;
    font-size: 28px;
    padding: 8px;
    border-radius: 50px;
    background-color: #2a3f83;
    opacity: 1;
}

    .desktop {
    display: block;
}

.mobile {
  display: none;
}

span.direitos {
    margin-top: 100px;
    display: block;
}

img.partner.certif {

  width: 100%;

}
img.goldPartner.certif {
  width:58%;
}

img.certificado.certif {
  max-width: 27%;
}
img.mPartners.certif {
  width: 40%;
}
img.meta {
    width: 59%;
    padding-top: 50px;
}
img#whatsapp {
    position: fixed;
    top: 59%;
    right: 25px;
    width: 100px;
    height: 100px;
}

 }

@media (max-width: 1440px) { 

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

img#whatsapp {
    position: fixed;
    top: 59%;
    right: 25px;
    width: 100px;
    height: 100px;
}

 }

@media (max-width: 1399px) { 

  img.partner.certif {
    width: 88%;
}

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

img#whatsapp {
    position: fixed;
    top: 59%;
    right: 25px;
    width: 100px;
    height: 100px;
}

 }

@media (max-width: 1199.98px) { 

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

img.certificado {
    width: 60%;
}

img#whatsapp {
    position: fixed;
    top: 59%;
    right: 25px;
    width: 100px;
    height: 100px;
}

 }

@media (max-width: 1024px) { 

  img.certificado {
    width: 50%;
}

  img.partner.certif {
    width: 80%;
}

img.goldPartner.certif {
    width: 100%;
}

  img.certificado.certif {
    margin: 50px 0px 0px 0px;
}

img.mPartners.certif {
    width: 90%;
}

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

img#whatsapp {
    position: fixed;
    top: 59%;
    right: 25px;
    width: 100px;
    height: 100px;
}

 }

@media (max-width: 991.98px) { 

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

img#whatsapp {
    position: fixed;
    top: 59%;
    right: 25px;
    width: 100px;
    height: 100px;
}

 }

@media (max-width: 767.98px) { 

 .desktop {
    display: none;
}

.mobile {
  display: block;
}

img.certificado.certif {
    margin: 50px 0px 50px 0px;
}

img#whatsapp {
    position: fixed;
    top: 59%;
    right: 25px;
    width: 100px;
    height: 100px;
}

 }

@media (max-width: 575.98px) { 

  a.gototop {
    color: #fff;
    position: relative;
    bottom: -18px;
    left: -295px;
    display: block;
    font-size: 28px;
    padding: 8px;
    border-radius: 50px;
    background-color: #2a3f83;
    opacity: 0.5;
}

  img.partner.certif {
    margin-bottom: 50px;
}

  img.certificado.certif {
    margin: 50px 0px;
}

img.goldPartner.certif {
    margin-bottom: 50px;
}

 .desktop {
    display: none;
}

.mobile {
  display: block;
}
img.certificado {
    width: 19%;
    margin: 25px 0px;
}

span.direitos {
    margin-top: 30px;
    display: block;
}

img.goldPartner.certif {
    width: 46%;
}

img.mPartners.certif {
    width: 47%;
    margin-bottom: 50px;
}

img#whatsapp {
        position: fixed;
        top: 59%;
        right: 95px;
        width: 70px;
        height: 70px;
}

 }

@media (max-width: 360px) { 

  a.gototop {
    color: #fff;
    position: relative;
    bottom: -18px;
    left: -295px;
    display: block;
    font-size: 28px;
    padding: 8px;
    border-radius: 50px;
    background-color: #2a3f83;
    opacity: 0.5;
}

    img.partner.certif {
    margin-bottom: 50px;
}

  img.certificado.certif {
    margin: 50px 0px;
}

img.goldPartner.certif {
    margin-bottom: 50px;
}

    .desktop {
    display: none;
}

.mobile {
  display: block;
}
img.certificado {
    width: 36%;
    margin: 25px 0px;
}
span.direitos {
    margin-top: 30px;
    display: block;
}

img#whatsapp {
        position: fixed;
        top: 59%;
        right: 95px;
        width: 70px;
        height: 70px;
}
 }
@media (max-width: 320px) { 

  a.gototop {
    color: #fff;
    position: relative;
    bottom: -18px;
    left: -295px;
    display: block;
    font-size: 28px;
    padding: 8px;
    border-radius: 50px;
    background-color: #2a3f83;
    opacity: 0.5;
}

    img.partner.certif {
    margin-bottom: 50px;
}

  img.certificado.certif {
    margin: 50px 0px;
}

img.goldPartner.certif {
    margin-bottom: 50px;
}

.desktop {
    display: none;
}

.mobile {
  display: block;
}
img.certificado {
    width: 36%;
    margin: 25px 0px;
}
span.direitos {
    margin-top: 30px;
    display: block;
}

img#whatsapp {
        position: fixed;
        top: 59%;
        right: 95px;
        width: 70px;
        height: 70px;
}
 }


</style>