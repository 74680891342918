<template>
    <div class="SetimaSecao">
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <span class="tituloSetima text-wrap">As 7 ações do Método MB</span>
                    <span class="subtituloSetima">Sua marca em evidência para seus clientes:</span><br>
                    <span class="descriptionSetima">
                        Acompanhe a evolução das campanhas.<br>
                        O Método MB identifica os pontos de melhoria no marketing presentes na maioria das empresas brasileiras.<br>
                        Confira os 7 points: 
                    </span>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center justify-content-lg-end">
                    <a href="https://api.whatsapp.com/send/?phone=5541987230882&text=Gostaria+de+informa%C3%A7%C3%B5es+sobre+como+usar+IA+no+marketing+e+vendas" target="_blank"><BotaoSite calltoaction="VENDER MAIS A PARTIR DE AGORA" /></a>
                </div>
                <div class="col-12 col-sm-4 col-md-3 col-lg-3 col-xl-3 d-flex justify-content-center" v-for="item in acoes" :key="item.id">
                    <div class="card" style="width: 218px;">
                        <div class="card-body">
                            <center><img class="icones-acoes" :src="item.imagem"></center>
                            <h5 class="card-title" v-html="item.titulo"></h5>
                            <p class="card-text" v-html="item.descricao"></p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 d-flex justify-content-center" v-for="item in acoes2" :key="item.id">
                    <div class="card" style="width: 218px;">
                        <div class="card-body">
                            <center><img class="icones-acoes" :src="item.imagem"></center>
                            <h5 class="card-title" v-html="item.titulo"></h5>
                            <p class="card-text" v-html="item.descricao"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Popup 
      :show="showDialog"
      titulo="Preencha o formulário para falar com nossos consultores"
      :cancel="cancel"
    />
</template>

<script>
import BotaoSite from './BotaoSite.vue';
import Popup from '@/components/Popup.vue'
export default {
    name: 'SetimaSecao',
    data: () => ({
        acoes: [
            {titulo: 'Público', descricao: 'Localize a melhor audiência', imagem: 'https://aumentar-vendas.desenvolvimentode.site/imagens/public.svg'},
            {titulo: 'Oferta', descricao: 'Ajuste a<br> oferta ideal', imagem: 'https://aumentar-vendas.desenvolvimentode.site/imagens/super-oferta.svg'},
            {titulo: 'Estratégia', descricao: 'Defina os passos da campanha', imagem: 'https://aumentar-vendas.desenvolvimentode.site/imagens/analysis.svg'},
            {titulo: 'Canal', descricao: 'Anuncie no<br> lugar certo', imagem: 'https://aumentar-vendas.desenvolvimentode.site/imagens/social-media.svg'},
        ],
        acoes2: [
            {titulo: 'Anúncios', descricao: 'Comece a conversa', imagem: 'https://aumentar-vendas.desenvolvimentode.site/imagens/adwords.svg'},
            {titulo: 'Página', descricao: 'Otimize a sua loja virtual', imagem: 'https://aumentar-vendas.desenvolvimentode.site/imagens/compras-online.svg'},
            {titulo: 'Vendas', descricao: 'Ao vender mais, volte sete passos', imagem: 'https://aumentar-vendas.desenvolvimentode.site/imagens/venda-rapida.svg'},
        ],
        showDialog: false
    }),
    props: [
    'mostrar'
  ],
  methods: {
    cancel () {
        this.showDialog = false;
      },
    mostrar () {
      this.showDialog = true
    }
  },
     components: {
    BotaoSite,
    Popup
}
}
</script>

<style scoped>

@media (max-width: 2000px) { 
    button.botaoPadrao {
    background-color: #0e992c;
    width: 301px;
    height: 48px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
}

    button.botaoPadrao:hover {
    background-color: #0e992c;
    width: 301px;
    height: 48px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
}


    span.tituloSetima {
    font-weight: 900;
    font-size: 36px;
    line-height: 46px;
    color: #fff;
    text-transform: uppercase;
    padding-bottom: 15px;
    display: block;
}
span.subtituloSetima {
    font-size: 20px;
    line-height: 23px;
    color: #fff;
    font-weight: 500;
}

span.descriptionSetima {
    font-size: 18px;
    font-weight: 300;
    line-height: 23px;
    color: #fff;
}

    .desktop {
    display: block;
}

.mobile {
  display: none;
}

.SetimaSecao {
    height: auto;
    padding: 110px 0px;
    background-image: url('@/assets/img/7/07.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 18px;
    height: 260px;
    margin-top: 150px;
    
}
h5.card-title {
    font-size: 20px;
    line-height: 25px;
    font-weight: 700;
    color: #737373;
    text-align: center;
}
p.card-text {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    text-align: center;
}

img.icones-acoes {
    padding-bottom: 30px;
    padding-top: 10px;
}
 }

@media (max-width: 1440px) { 

    .SetimaSecao {
    height: auto;
    padding: 110px 0px;
    background-image: url('@/assets/img/7/07.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
  
 .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 1399px) { 

    .SetimaSecao {
    height: auto;
    padding: 110px 0px;
    background-image: url('@/assets/img/7/07.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

    span.tituloSetima {
    font-weight: 900;
    font-size: 35px;
    line-height: 46px;
    color: #fff;
    text-transform: uppercase;
    padding-bottom: 15px;
    display: block;
}

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 1199.98px) { 

    span.tituloSetima {
    font-weight: 900;
    font-size: 28px;
    line-height: 46px;
    color: #fff;
    text-transform: uppercase;
    padding-bottom: 15px;
    display: block;
}

span.subtituloSetima {
    font-size: 19px;
    line-height: 23px;
    color: #fff;
    font-weight: 500;
}

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 1024px) { 

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 991.98px) { 

    span.tituloSetima {
    font-weight: 900;
    font-size: 20px;
    line-height: 46px;
    color: #fff;
    text-transform: uppercase;
    padding-bottom: 15px;
    display: block;
}

span.descriptionSetima {
    font-size: 16px;
    font-weight: 300;
    line-height: 23px;
    color: #fff;
}

 .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 767.98px) { 

    .SetimaSecao {
    height: auto;
    padding: 110px 0px;
    background-image: url('@/assets/img/7/07.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

 .desktop {
    display: none;
}

.mobile {
  display: block;
}

button.botaoPadrao {
    background-color: #00C02A;
    width: 400px;
    height: 38px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 26px;
    transition: .2s;
}

button.botaoPadrao:hover {
    background-color: #00C02A;
    width: 400px;
    height: 38px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 26px;
    transition: .2s;
}

 }

@media (max-width: 575.98px) { 

 .desktop {
    display: none;
}

.mobile {
  display: block;
}

.SetimaSecao {
    height: auto;
    padding: 110px 0px;
    background-image: url('@/assets/img/7/07.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 18px;
    height: 260px;
    margin-top: 70px;
}
span.tituloSetima {
    font-weight: 900;
    font-size: 20px;
    line-height: 46px;
    color: #fff;
    text-transform: uppercase;
    padding-bottom: 15px;
    display: block;
    text-align: center;
}

span.subtituloSetima {
    font-size: 19px;
    line-height: 23px;
    color: #fff;
    font-weight: 500;
    text-align: center;
    position: relative;
    display: block;
}
span.descriptionSetima {
    font-size: 15px;
    font-weight: 300;
    line-height: 23px;
    color: #fff;
    text-align: center;
    display: block;
}

button.botaoPadrao {
    background-color: #00C02A;
    width: 400px;
    height: 48px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 15px;
    line-height: 26px;
    transition: .2s;
    margin: 20px;
}

button.botaoPadrao:hover {
    background-color: #00C02A;
    width: 400px;
    height: 48px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 15px;
    line-height: 26px;
    transition: .2s;
    margin: 20px;
}

 }

@media (max-width: 360px) { 

    .SetimaSecao {
    height: auto;
    padding: 110px 0px;
    background-image: url('@/assets/img/7/07.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

    .desktop {
    display: none;
}

.mobile {
  display: block;
}
 }
@media (max-width: 320px) { 

    .SetimaSecao {
    height: auto;
    padding: 110px 0px;
    background-image: url('@/assets/img/7/07.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.desktop {
    display: none;
}

.mobile {
  display: block;
}
 }


</style>