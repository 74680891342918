<template>
  <nav class="navbar navbar-light">
        <div class="container primeira">
              <div class="col-6 col-md-6 col-sm-6 col-lg-6 col-xm-6">
                <a class="navbar-brand" href="/"><img id="logotop" src="@/assets/img/1/logo_mb.svg"></a>
              </div>  
              <div class="col-6 col-md-6 col-sm-6 col-lg-6 col-xm-6 d-flex justify-content-end">
                <span class="">
                <a href="https://api.whatsapp.com/send/?phone=5541987230882&text=Gostaria+de+informa%C3%A7%C3%B5es+sobre+como+usar+IA+no+marketing+e+vendas" target="_blank"><button class="btn btn-outline-success whatsapp"> <img id="btn-whats" src=""> <img id="wcall" src="@/assets/img/1/WhatsApp.svg"> Fale Conosco</button></a>
                </span>
              </div>  
        </div>
    </nav>
     <Popup 
      :show="showDialog"
      titulo="Preencha o formulário para falar com nossos consultores"
      :cancel="cancel"
    />
</template>

<script>
// @ is an alias to /src
import Popup from '@/components/Popup.vue'

export default {
  name: 'TopBar',
  data: () => ({
      showDialog: false
  }),
  props: [
    'mostrar'
  ],
  methods: {
    cancel () {
        this.showDialog = false;
      },
    mostrar () {
      this.showDialog = true
    }
  },
  components: {
      Popup
    }
}
</script>



<style scoped>


/* media css */


.container.primeira {
    padding-top: 50px;
}

@media (max-width: 2000px) { 

   .desktop {
    display: block;
}

.mobile {
  display: none;
}

/* topo */
button.btn.btn-outline-success.whatsapp {
    color: #491C77;
    width: 242px;
    height: 47px;
    border-radius: 47px;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
    background-color: #fff;
    border: 0px;
}

}

@media (max-width: 1440px) { 

     .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 1399px) { 

     .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 1199.98px) { 

     .desktop {
    display: block;
}

.mobile {
  display: none;
}

 }

@media (max-width: 1024px) { 

   .desktop {
    display: block;
}

.mobile {
  display: none;
}

/* 1 */
#subtituloprimeira {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
}

 }

@media (max-width: 991.98px) { 

   .desktop {
    display: block;
}

.mobile {
  display: none;
}

/* 1 */
.titulo {
    font-weight: 900;
    font-size: 24px;
    line-height: 33px;
    font-family: var(--font-padrao);
    text-transform: uppercase;
}
#subtituloprimeira {
    font-size: 13px;
    line-height: 24px;
    font-weight: 400;
}

 }

@media (max-width: 767.98px) { 

     .desktop {
    display: none;
}

.mobile {
  display: block;
}

 }

@media (max-width: 575.98px) { 

   .desktop {
    display: none;
}

.mobile {
  display: block;
}

/*topo*/
.container.primeira {
    padding-top: 0px;
}

img#logotop {
    width: 143px;
    height: auto;
}
button.btn.btn-outline-success.whatsapp {
    color: #491C77;
    width: 145px;
    height: 27px;
    border-radius: 47px;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    background-color: #fff;
    border: 0px;
}

img#wcall {
    width: 11px;
    height: auto;
}



 }

@media (max-width: 360px) { 

 .desktop {
    display: none;
}

.mobile {
  display: block;
}

/* topo  */

.container.primeira {
    padding-top: 0px;
}

img#logotop {
    width: 85px;
    height: 24px;
}
button.btn.btn-outline-success.whatsapp {
    color: #491C77;
    width: 145px;
    height: 27px;
    border-radius: 47px;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    background-color: #fff;
    border: 0px;
}

img#wcall {
    width: 11px;
    height: auto;
}

 }

@media (max-width: 320px) { 

 .desktop {
    display: none;
}

.mobile {
  display: block;
}
img#logotop {
    width: 85px;
    height: 24px;
}
button.btn.btn-outline-success.whatsapp {
    color: #491C77;
    width: 126px;
    height: 19px;
    border-radius: 47px;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    background-color: #fff;
    border: 0px;
}

 }


</style>