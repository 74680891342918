<template>

    <button :class="`botaoPadrao`"> <img class="iconw" v-bind:src="imgw"> {{calltoaction}}</button>
    
</template>

<script>
export default {
    name: 'BotaoSite',
    props: [
    'calltoaction',
    'imgw'
  ]
}
</script>

<style scoped>

button.botaoPadrao {
    background-color: #00C02A;
    width: auto;
    height: 55px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
}
button.botaoPadrao:hover {
    background-color: #0e992c;
    width: auto;
    height: 55px;
    border-radius: 47px;
    border: 0px;
    color: var(--branco);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
    transition: .2s;
}

</style>